import type { AppDispatch } from '@/store/store';
import { SOCKET_EVENTS } from '@/constants/socketEvents';
import type { UndecoratedFile } from '@witmetrics/api-client';
import type { Subscribe } from '@/types/sockets';
import { addFile, deleteFile, updateFile } from '@/store/slices/filesSlice';

const { CREATE_FILE, UPDATE_FILE, DELETE_FILE } = SOCKET_EVENTS;

export function subscribeFiles(dispatch: AppDispatch, subscribe: Subscribe) {
  subscribe({
    endpoint: CREATE_FILE,
    callback: ({ file }: { file: UndecoratedFile }) => dispatch(addFile(file)),
  });
  subscribe({
    endpoint: UPDATE_FILE,
    callback: ({ file }: { file: UndecoratedFile }) =>
      dispatch(updateFile(file)),
  });
  subscribe({
    endpoint: DELETE_FILE,
    callback: ({ fileID }: { fileID: number }) => {
      dispatch(deleteFile({ fileID }));
    },
  });
}
