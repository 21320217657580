import type { AppDispatch } from '@/store/store';
import { SOCKET_EVENTS } from '@/constants/socketEvents';
import type { DecoratedUnisonProjectTask } from '@witmetrics/api-client';
import type { Subscribe } from '@/types/sockets';
import { addUnisonProjectTask } from '@/store/slices/unisonProjectTasksSlice';

const { CREATE_UNISON_PROJECT_TASK } = SOCKET_EVENTS;

export function subscribeUnisonProjectTasks(
  dispatch: AppDispatch,
  subscribe: Subscribe
) {
  subscribe({
    endpoint: CREATE_UNISON_PROJECT_TASK,
    callback: ({
      unisonProjectTask,
    }: {
      unisonProjectTask: DecoratedUnisonProjectTask;
    }) => dispatch(addUnisonProjectTask(unisonProjectTask)),
  });
}
