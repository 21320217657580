export const COMPARISON_OPERATORS = {
  EQUALS: { name: 'EQUALS', label: 'Equals', value: '=' },
  DOES_NOT_EQUAL: {
    name: 'DOES_NOT_EQUAL',
    label: `Doesn't equal`,
    value: '!=',
  },
  GREATER_THAN: { name: 'GREATER_THAN', label: 'Greater than', value: '>' },
  LESS_THAN: { name: 'LESS_THAN', label: 'Less than', value: '<' },
  GREATER_THAN_OR_EQUALS: {
    name: 'GREATER_THAN_OR_EQUALS',
    label: 'Greater than or equal to',
    value: '>=',
  },
  LESS_THAN_OR_EQUALS: {
    name: 'LESS_THAN_OR_EQUALS',
    label: 'Less than or equal to',
    value: '<=',
  },
  INCLUDES: {
    name: 'INCLUDES',
    label: 'Includes',
    value: 'IN',
  },
  IS: {
    name: 'IS',
    label: 'Is',
    value: 'IS',
  },
  NOT: {
    name: 'NOT',
    label: 'Not',
    value: 'NOT',
  },
  BETWEEN: {
    name: 'BETWEEN',
    label: 'Between',
    value: 'BETWEEN',
  },
  NOT_BETWEEN: {
    name: 'NOT_BETWEEN',
    label: 'Not Between',
    value: 'NOT BETWEEN',
  },
  IN: {
    name: 'IN',
    label: 'Matches',
    value: 'IN',
  },
  NOT_IN: {
    name: 'NOT_IN',
    label: "Doesn't match",
    value: 'NOT IN',
  },
} as const;
