import { API } from '@/api';
import {
  type NewBookingNotification,
  type NewInteractionCommentMentionNotification,
  type NewInteractionMentionNotification,
  type NewLeadTaskNotification,
  type NewSequenceNoteCommentMentionNotification,
  type NewSequenceNoteMentionNotification,
  type NewSequenceStepNoteCommentMentionNotification,
  type NewSequenceStepNoteMentionNotification,
  type NewUnisonProjectNoteCommentMentionNotification,
  type NewUnisonProjectNoteMentionNotification,
  type NewUnisonProjectTaskNotification,
  type Notification,
} from '@witmetrics/api-client';
import type { Dictionary } from '@/types';
import { ACTIVE_ACCOUNTS } from '@/constants/activeAccounts';
import { DIALOGS } from '@/constants/dialogs';
import { OVERLAYS } from '@/constants/overlays';
import {
  isNewBookingNotification,
  isNewInteractionCommentMention,
  isNewInteractionMention,
  isNewLeadTaskNotification,
  isNewSequenceNoteCommentMention,
  isNewSequenceNoteMention,
  isNewSequenceStepNoteCommentMention,
  isNewSequenceStepNoteMention,
  isNewUnisonProjectNoteCommentMention,
  isNewUnisonProjectNoteMention,
  isNewUnisonProjectTask,
} from '@/utils/notifications';
import { useSessionStorage } from '@/hooks/useStorage';

export const DEFAULT_DIALOGS: Dictionary<boolean> = {
  [DIALOGS.ACCOUNTS]: false,
  [DIALOGS.LOGIN]: false,
  [DIALOGS.SEARCH]: false,
  [DIALOGS.SHORTCUTS]: false,
};

export const DEFAULT_OVERLAYS: Dictionary<boolean> = {
  [OVERLAYS.AGENDA]: false,
};

export function useStoredAccountID() {
  return useSessionStorage<number | null>('activeAccountID', null, true);
}

export async function updateActiveAccount(accountID: number) {
  await API.Users.setActiveAccount(ACTIVE_ACCOUNTS.PRACTICE, accountID);
  return {
    accountType: ACTIVE_ACCOUNTS.PRACTICE,
    accountID,
  };
}

export function updateReadNotification(notificationID: number) {
  return API.Notifications.updateNotification(notificationID, { isRead: true });
}

export function isProjectNotification(
  notification: Notification
): notification is
  | NewUnisonProjectTaskNotification
  | NewUnisonProjectNoteMentionNotification
  | NewUnisonProjectNoteCommentMentionNotification {
  return (
    isNewUnisonProjectTask(notification) ||
    isNewUnisonProjectNoteMention(notification) ||
    isNewUnisonProjectNoteCommentMention(notification)
  );
}

export function isSequenceNotification(
  notification: Notification
): notification is
  | NewSequenceNoteMentionNotification
  | NewSequenceNoteCommentMentionNotification {
  return (
    isNewSequenceNoteMention(notification) ||
    isNewSequenceNoteCommentMention(notification)
  );
}

export function isSequenceStepNotification(
  notification: Notification
): notification is
  | NewSequenceStepNoteMentionNotification
  | NewSequenceStepNoteCommentMentionNotification {
  return (
    isNewSequenceStepNoteMention(notification) ||
    isNewSequenceStepNoteCommentMention(notification)
  );
}

export function isLeadNotification(
  notification: Notification
): notification is
  | NewBookingNotification
  | NewLeadTaskNotification
  | NewInteractionMentionNotification
  | NewInteractionCommentMentionNotification {
  return (
    isNewBookingNotification(notification) ||
    isNewLeadTaskNotification(notification) ||
    isNewInteractionMention(notification) ||
    isNewInteractionCommentMention(notification)
  );
}
