import { ACTIVE_ACCOUNTS } from '@/constants/activeAccounts';
import { RootState } from '@/store/store';
import { PREFERENCE_IDS } from '@/constants/preferences';
import type { TreeItem } from '@/types/trees';
import type {
  DecoratedChecklistItem,
  DecoratedConversationMessage,
  DecoratedConversationMessageFile,
  DecoratedConversationMessageFileCollection,
  DecoratedConversationUnisonProject,
  DecoratedConversationUser,
  DecoratedConversationWithProject,
  DecoratedFileCollection,
  DecoratedPracticePreference,
  DecoratedPracticeUser,
  DecoratedSequenceStepFile,
  DecoratedUnisonProject,
  DecoratedUnisonProjectChecklist,
  DecoratedUnisonProjectConversation,
  DecoratedUnisonProjectNote,
  DecoratedUnisonProjectTask,
  DecoratedUserPractice,
  NoteComment,
  Notification,
  UndecoratedFile,
  UndecoratedFileCollection,
  UndecoratedSequence,
  UndecoratedSequenceStep,
  UndecoratedUserPreference,
} from '@witmetrics/api-client';
import { buildUserPreferenceKey } from '@/store/slices/userPreferencesSlice';
import { TREE_ITEM_TYPES } from '@/constants/treeItemTypes';
import { formatTreeID, parseCompositeID } from '@/utils/files';
import { filterBlanks } from '@/utils/arrays';
import { ParsedProperty } from '@/types/properties';
import { Dictionary } from '@/types';
import { WeekStartsOn } from '@/types/dates';

export type Identifier = number | string;

const { PRACTICE, ORGANIZATION } = ACTIVE_ACCOUNTS;

const { FILE, FILE_COLLECTION } = TREE_ITEM_TYPES;

function getAccountID(state: RootState, practiceID?: Identifier) {
  let accountID = practiceID;
  if (!practiceID && state.activeAccount) {
    accountID = state.activeAccount.accountID;
  }
  return accountID;
}

function buildKey(identifier: Identifier | Identifier[]) {
  if (Array.isArray(identifier)) {
    return identifier.join('.');
  } else return `${identifier}`;
}

function buildFromIDArray<T, IDType>(
  state: RootState,
  sliceName: keyof Omit<
    RootState,
    'activeAccount' | 'currentUser' | 'unreadNotificationsCount'
  >,
  buildMethod: (state: RootState, id: IDType) => any,
  idArray: IDType[]
): T[] {
  if (!idArray) return [];
  return filterBlanks(
    idArray
      .filter((id) => Object.keys(state[sliceName].byID).includes(`${id}`))
      .map((id) => buildMethod(state, id))
  );
}

export function buildActiveAccount(state: RootState) {
  if (!state.activeAccount) return state.activeAccount;
  const accountType = state.activeAccount.accountType.toUpperCase();
  const key = buildKey(state.activeAccount.accountID);
  let account;
  if (accountType === PRACTICE) {
    account = state.practices.byID[key];
  } else if (accountType === ORGANIZATION) {
    throw Error('Organization accounts are not currently supported');
  } else {
    throw Error(`Unrecognized account type: "${accountType}"`);
  }
  return { ...state.activeAccount, account };
}

export function buildCurrentUser(state: RootState) {
  if (!state.currentUser) return state.currentUser;
  return {
    ...state.currentUser,
    userPractices: buildUserPractices(state, state.currentUser.id) || [],
    userPreferences: buildUserPreferences(state, state.currentUser.id),
  };
}

export function buildUserPreferences(
  state: RootState,
  userID: Identifier
): { [key: string]: UndecoratedUserPreference } | null {
  const key = buildKey(userID);
  const userPreferences = state.userPreferences.byUserID[key];
  if (!userPreferences) return null;
  return filterBlanks(
    userPreferences.map((id) => buildUserPreference(state, parseInt(id)))
  ).reduce((a, b) => ({ ...a, [b.preference.name]: b }), {});
}

export function buildUserPreference(
  state: RootState,
  userPreferenceID: Identifier
) {
  const key = buildKey(userPreferenceID);
  const userPreference = state.userPreferences.byID[key];
  if (!userPreference) return null;
  return {
    ...userPreference,
    preference: buildPreference(state, userPreference.preferenceID),
  };
}

export function buildPreference(state: RootState, preferenceID: Identifier) {
  const key = buildKey(preferenceID);
  const preference = state.preferences.byID[key];
  return preference || null;
}

export function buildCurrentUserPreference(
  state: RootState,
  preferenceID: Identifier
) {
  if (!state.currentUser) return null;
  const key = buildUserPreferenceKey(state.currentUser.id, preferenceID);
  const userPreference = state.userPreferences.byID[key];
  if (!userPreference) return null;
  return userPreference.value;
}

export function buildDateFormatPreference(state: RootState) {
  return buildCurrentUserPreference(state, PREFERENCE_IDS.DATE_FORMAT) as
    | string
    | undefined;
}

export function buildWeekStartsOnPreference(state: RootState) {
  const preference = buildCurrentUserPreference(
    state,
    PREFERENCE_IDS.WEEK_STARTS_ON
  );
  if (!preference) return undefined;
  return parseInt(preference as string) as WeekStartsOn;
}

export function buildLead(state: RootState, leadID: Identifier) {
  const key = buildKey(leadID);
  if (!leadID || !state.leads.byID[key]) return null;
  return {
    ...state.leads.byID[key],
    userProperties: buildLeadUserProperties(state, key),
    interactions: buildLeadInteractions(state, key),
  };
}

export function buildLeadUserProperties(state: RootState, leadID: Identifier) {
  const key = buildKey(leadID);
  const userProperties = state.userProperties.byUserID[key];
  if (!userProperties) return {};
  return userProperties.reduce((a, upID) => {
    const userProperty = state.userProperties.byID[buildKey(upID)];
    const property = state.properties.byID[buildKey(userProperty.propertyID)];
    return property
      ? { ...a, [property.name]: { ...userProperty, property } }
      : a;
  }, {});
}

export function buildLeadInteractions(state: RootState, leadID: Identifier) {
  const key = buildKey(leadID);
  const interactions = state.interactions.byUserID[key];
  if (!interactions) return [];
  return filterBlanks(interactions.map((id) => buildInteraction(state, id)));
}

export function buildInteraction(state: RootState, interactionID: Identifier) {
  const key = buildKey(interactionID);
  if (!state.interactions.byID[key]) return null;
  const interaction = { ...state.interactions.byID[key] };
  return {
    ...interaction,
    comments: buildInteractionComments(state, interactionID),
  };
}

export function buildInteractionComments(
  state: RootState,
  interactionID: Identifier
) {
  const key = buildKey(interactionID);
  const commentIDs = state.interactionComments.byInteractionID[key];
  if (!commentIDs) return [];
  return filterBlanks(
    commentIDs.map((id) => buildInteractionComment(state, id))
  );
}

function buildInteractionComment(state: RootState, commentID: Identifier) {
  const key = buildKey(commentID);
  return state.interactionComments.byID[key] || null;
}

export function buildUserPractices(
  state: RootState,
  userID: Identifier
): DecoratedUserPractice[] {
  const key = buildKey(userID);
  const userPracticeIDs = state.userPractices.byUserID[key];
  if (!userPracticeIDs) return [];
  return buildFromIDArray<DecoratedUserPractice, string>(
    state,
    'userPractices',
    buildUserPractice,
    userPracticeIDs
  );
}

export function buildPracticeUser(
  state: RootState,
  userPracticeID: Identifier
): DecoratedPracticeUser | null {
  const key = buildKey(userPracticeID);
  const practiceUser = state.userPractices.byID[key];
  if (!practiceUser) return null;
  const user = buildUser(state, practiceUser.userID);
  if (!user) return null;
  return { ...practiceUser, user };
}

export function buildUser(state: RootState, userID: Identifier) {
  const key = buildKey(userID);
  return state.users.byID[key] || null;
}

export function buildUserPractice(
  state: RootState,
  userPracticeID: Identifier
): DecoratedUserPractice | null {
  const key = buildKey(userPracticeID);
  const userPractice = state.userPractices.byID[key];
  if (!userPractice) return null;
  return {
    ...userPractice,
    practice: buildPractice(state, userPractice.practiceID),
  };
}

export function buildPractice(state: RootState, practiceID: Identifier) {
  return state.practices.byID[buildKey(practiceID)];
}

export function buildPracticePreferences(
  state: RootState,
  practiceID?: Identifier
): { [key: string]: DecoratedPracticePreference } | null {
  const accountID = getAccountID(state, practiceID);
  if (!accountID) return null;
  const key = buildKey(accountID);
  const idArray = state.practicePreferences.byPracticeID[key];
  if (!idArray) return null;
  return buildFromIDArray<DecoratedPracticePreference, string>(
    state,
    'practicePreferences',
    buildPracticePreference,
    idArray
  )!.reduce((a, b) => ({ ...a, [b.preference.name]: b }), {});
}

export function buildPracticePreference(
  state: RootState,
  practicePreferenceID: Identifier
) {
  const key = buildKey(practicePreferenceID);
  const practicePreference = state.practicePreferences.byID[key];
  if (!practicePreference) return null;
  return {
    ...practicePreference,
    preference: buildPreference(state, practicePreference.preferenceID),
  };
}

export function buildLogoPreference(state: RootState, practiceID?: Identifier) {
  const accountID = getAccountID(state, practiceID);
  if (!accountID) return null;
  const key = buildKey([accountID, PREFERENCE_IDS.LOGO]);
  return buildPracticePreference(state, key);
}

export function buildPracticeProperties(
  state: RootState,
  practiceID: Identifier
): Dictionary<ParsedProperty> {
  const accountID = getAccountID(state, practiceID);
  if (!accountID) return {};
  const key = buildKey(accountID);
  const propertyIDs = state.properties.byPracticeID[key];
  if (!propertyIDs) return {};
  return propertyIDs
    .filter((pid) => Object.keys(state.properties.byID).includes(`${pid}`))
    .map((pid) => state.properties.byID[pid])
    .reduce((a, b) => ({ ...a, [b.name]: b }), {});
}

export function buildPracticeUsers(state: RootState, practiceID: Identifier) {
  const accountID = getAccountID(state, practiceID);
  if (!accountID) return [];
  const key = buildKey(accountID);
  const userIDs = state.userPractices.byPracticeID[key];
  if (!userIDs) return [];
  return buildFromIDArray<DecoratedPracticeUser, string>(
    state,
    'userPractices',
    buildPracticeUser,
    userIDs
  );
}

export function buildPracticeUnisonProjects(
  state: RootState,
  practiceID?: Identifier
) {
  const accountID = getAccountID(state, practiceID);
  if (!accountID) return null;
  const idArray = state.unisonProjects.byPracticeID[accountID]?.map((id) =>
    parseInt(id)
  );
  return buildUnisonProjectsFromIDArray(state, idArray || []);
}

export function buildUnisonProjectsFromIDArray(
  state: RootState,
  idArray: Identifier[]
) {
  return buildFromIDArray<DecoratedUnisonProject, Identifier>(
    state,
    'unisonProjects',
    buildUnisonProject,
    idArray
  );
}

export function buildUnisonProject(
  state: RootState,
  unisonProjectID: Identifier
) {
  const key = buildKey(unisonProjectID);
  const unisonProject = state.unisonProjects.byID[key];
  if (!unisonProject) return null;
  return unisonProject;
}

export function buildNotesFromUnisonProjectID(
  state: RootState,
  unisonProjectID: Identifier
) {
  const key = buildKey(unisonProjectID);
  const projectNoteKeys = state.unisonProjectNotes.byUnisonProjectID[key];
  if (!projectNoteKeys) return null;
  const unisonProjectNotes = buildUnisonProjectNotesFromIDArray(
    state,
    projectNoteKeys
  );
  return unisonProjectNotes?.map((upn) => upn.note) || null;
}

export function buildUnisonProjectNotesFromIDArray(
  state: RootState,
  idArray: Identifier[]
) {
  return buildFromIDArray<DecoratedUnisonProjectNote, Identifier>(
    state,
    'unisonProjectNotes',
    buildUnisonProjectNote,
    idArray
  );
}

export function buildUnisonProjectNote(
  state: RootState,
  unisonProjectNoteID: Identifier
) {
  const key = buildKey(unisonProjectNoteID);
  const unisonProjectNote = state.unisonProjectNotes.byID[key];
  if (!unisonProjectNote) return null;
  return {
    ...unisonProjectNote,
    note: buildNote(state, unisonProjectNote.noteID),
  };
}

export function buildUnisonProjectTaskFromTaskID(
  state: RootState,
  taskID: Identifier
) {
  const key = state.unisonProjectTasks.byTaskID[taskID];
  if (!key) return null;
  return buildUnisonProjectTask(state, key);
}

export function buildUnisonProjectTask(state: RootState, key: Identifier) {
  const unisonProjectTask = state.unisonProjectTasks.byID[key];
  if (!unisonProjectTask) return null;
  const task = buildTask(state, unisonProjectTask.taskID);
  const unisonProject = buildUnisonProject(
    state,
    unisonProjectTask.unisonProjectID
  );
  if (!task || !unisonProject) return null;
  return { ...unisonProjectTask, task, unisonProject };
}

export function buildTask(state: RootState, taskID: Identifier) {
  const key = buildKey(taskID);
  if (!state.tasks.byID[key]) return null;
  return state.tasks.byID[key];
}

export function buildTasksFromUnisonProjectID(
  state: RootState,
  unisonProjectID: Identifier
) {
  const key = buildKey(unisonProjectID);
  const projectTaskKeys = state.unisonProjectTasks.byUnisonProjectID[key];
  if (!projectTaskKeys) return [];
  return buildUnisonProjectTasksFromIDArray(state, projectTaskKeys);
}

export function buildAllUnisonProjectTasks(state: RootState) {
  const idArray = Object.keys(state.unisonProjectTasks.byID);
  return buildFromIDArray<DecoratedUnisonProjectTask, Identifier>(
    state,
    'unisonProjectTasks',
    buildUnisonProjectTask,
    idArray
  );
}

export function buildUnisonProjectTasksFromIDArray(
  state: RootState,
  idArray: Identifier[]
) {
  return buildFromIDArray<DecoratedUnisonProjectTask, Identifier>(
    state,
    'unisonProjectTasks',
    buildUnisonProjectTask,
    idArray
  );
}

export function buildChecklistsFromUnisonProjectID(
  state: RootState,
  unisonProjectID: Identifier
) {
  const key = buildKey(unisonProjectID);
  const projectChecklistKeys =
    state.unisonProjectChecklists.byUnisonProjectID[key];
  if (!projectChecklistKeys) return [];
  const unisonProjectChecklists = buildUnisonProjectChecklistsFromIDArray(
    state,
    projectChecklistKeys
  );
  return unisonProjectChecklists?.map((upc) => upc.checklist) || null;
}

export function buildUnisonProjectChecklistsFromIDArray(
  state: RootState,
  idArray: Identifier[]
) {
  return buildFromIDArray<DecoratedUnisonProjectChecklist, Identifier>(
    state,
    'unisonProjectChecklists',
    buildUnisonProjectChecklist,
    idArray
  );
}

export function buildUnisonProjectChecklist(
  state: RootState,
  unisonProjectChecklistID: Identifier
) {
  const key = buildKey(unisonProjectChecklistID);
  const unisonProjectChecklist = state.unisonProjectChecklists.byID[key];
  if (!unisonProjectChecklist) return null;
  return {
    ...unisonProjectChecklist,
    checklist: buildChecklist(state, unisonProjectChecklist.checklistID),
  };
}

export function buildChecklist(state: RootState, checklistID: Identifier) {
  const key = buildKey(checklistID);
  const checklist = state.checklists.byID[key];
  if (!checklist) return null;
  const itemIDs = checklist.itemOrder;
  let items = buildFromIDArray<DecoratedChecklistItem, Identifier>(
    state,
    'checklistItems',
    buildChecklistItem,
    itemIDs
  );
  return {
    ...state.checklists.byID[key],
    items: items || [],
  };
}

export function buildChecklistItem(state: RootState, itemID: Identifier) {
  if (!itemID) return null;
  const key = buildKey(itemID);
  return state.checklistItems.byID[key] || null;
}

export function buildNote(state: RootState, noteID: Identifier) {
  if (!noteID) return null;
  const key = buildKey(noteID);
  const note = state.notes.byID[key];
  if (!note) return null;
  return {
    ...note,
    noteComments: buildNoteComments(state, noteID),
  };
}

export function buildNoteComments(state: RootState, noteID: Identifier) {
  const key = buildKey(noteID);
  const commentIDs = state.noteComments.byNoteID[key];
  if (!commentIDs) return [];
  return buildFromIDArray<NoteComment, Identifier>(
    state,
    'noteComments',
    buildNoteComment,
    commentIDs
  );
}

export function buildNoteComment(state: RootState, commentID: Identifier) {
  const key = buildKey(commentID);
  return state.noteComments.byID[key] || null;
}

export function buildFileCollection(
  state: RootState,
  fileCollectionID: Identifier
) {
  const key = buildKey(fileCollectionID);
  return state.fileCollections.byID[key] || null;
}

export function buildFileCollectionsFromIDArray(
  state: RootState,
  idArray: Identifier[]
) {
  return buildFromIDArray<DecoratedFileCollection, Identifier>(
    state,
    'fileCollections',
    buildFileCollection,
    idArray
  );
}

export function buildFileCollectionsFromParentID(
  state: RootState,
  parentFileCollectionID?: Identifier | null
) {
  if (parentFileCollectionID === undefined) return null;
  const key =
    parentFileCollectionID === null ? 'null' : buildKey(parentFileCollectionID);
  const fileCollectionIDs = state.fileCollections.byParentID[key];
  if (!fileCollectionIDs) return null;
  return buildFromIDArray<DecoratedFileCollection, number>(
    state,
    'fileCollections',
    buildFileCollection,
    fileCollectionIDs.map((id) => parseInt(id))
  );
}

export function buildFilesFromIDArray(state: RootState, idArray: Identifier[]) {
  return buildFromIDArray<UndecoratedFile, Identifier>(
    state,
    'files',
    buildFile,
    idArray
  );
}

export function buildFilesFromFileCollectionID(
  state: RootState,
  fileCollectionID: Identifier | null
) {
  if (!fileCollectionID) return [];
  const key = buildKey(fileCollectionID);
  const fileCollectionFileIDs =
    state.fileCollectionFiles.byFileCollectionID[key];
  if (!fileCollectionFileIDs) return null;
  const fileIDs = fileCollectionFileIDs.map(parseCompositeID);
  return buildFromIDArray<UndecoratedFile, Identifier>(
    state,
    'files',
    buildFile,
    fileIDs
  );
}

export function buildFile(state: RootState, fileID: Identifier) {
  const key = buildKey(fileID);
  return state.files.byID[key] || null;
}

export function buildFileVersionFromFileID(
  state: RootState,
  fileID: Identifier
) {
  const key = buildKey(fileID);
  const fileVersionIDs = state.fileVersions.byFileID[key];
  if (!fileVersionIDs || fileVersionIDs.length === 0) return null;
  return buildFileVersion(state, fileVersionIDs[0]);
}

export function buildFileVersion(state: RootState, fileVersionID: Identifier) {
  const key = buildKey(fileVersionID);
  return state.fileVersions.byID[key] || null;
}

export function buildConversationsFromUnisonProjectID(
  state: RootState,
  unisonProjectID: Identifier
) {
  const key = buildKey(unisonProjectID);
  const projectConversationKeys =
    state.unisonProjectConversations.byUnisonProjectID[key];
  if (!projectConversationKeys) return [];
  const unisonProjectConversations = buildUnisonProjectConversationsFromIDArray(
    state,
    projectConversationKeys
  );
  return unisonProjectConversations?.map((upc) => upc.conversation) || null;
}

export function buildUnisonProjectConversationsFromIDArray(
  state: RootState,
  idArray: Identifier[]
) {
  return buildFromIDArray<DecoratedUnisonProjectConversation, Identifier>(
    state,
    'unisonProjectConversations',
    buildUnisonProjectConversation,
    idArray
  );
}

export function buildUnisonProjectConversation(
  state: RootState,
  unisonProjectConversationID: Identifier
) {
  const key = buildKey(unisonProjectConversationID);
  const unisonProjectConversation = state.unisonProjectConversations.byID[key];
  if (!unisonProjectConversation) return null;
  return {
    ...unisonProjectConversation,
    conversation: buildConversation(
      state,
      unisonProjectConversation.conversationID
    ),
  };
}

export function buildConversationsFromPracticeID(
  state: RootState,
  practiceID: Identifier
) {
  const accountID = getAccountID(state, practiceID);
  if (!accountID) return null;
  const key = buildKey(accountID);
  const conversationKeys = state.conversations.byPracticeID[key];
  if (!conversationKeys) return null;
  return buildConversationsFromIDArray(state, conversationKeys);
}

export function buildConversationsFromIDArray(
  state: RootState,
  idArray: Identifier[]
) {
  return buildFromIDArray<DecoratedConversationWithProject, Identifier>(
    state,
    'conversations',
    buildConversation,
    idArray
  );
}

export function buildConversation(
  state: RootState,
  conversationID: Identifier
) {
  const key = buildKey(conversationID);
  if (!state.conversations.byID[key]) return null;
  return {
    ...state.conversations.byID[key],
    users: buildConversationUsers(state, conversationID),
    unisonProjects: buildUnisonProjectsFromConversationID(
      state,
      conversationID
    ),
  };
}

export function buildUnisonProjectsFromConversationID(
  state: RootState,
  conversationID: Identifier
) {
  const key = buildKey(conversationID);
  const keys = state.unisonProjectConversations.byConversationID[key];
  if (!keys) return [];
  return buildFromIDArray<DecoratedConversationUnisonProject, Identifier>(
    state,
    'unisonProjectConversations',
    buildConversationUnisonProject,
    keys
  );
}

export function buildConversationUnisonProject(
  state: RootState,
  conversationUnisonProjectID: Identifier
) {
  const key = buildKey(conversationUnisonProjectID);
  if (!state.unisonProjectConversations.byID[key]) return null;
  return {
    ...state.unisonProjectConversations.byID[key],
    unisonProject: buildUnisonProject(
      state,
      state.unisonProjectConversations.byID[key].unisonProjectID
    ),
  };
}

export function buildConversationMessages(
  state: RootState,
  conversationID: Identifier
) {
  const key = buildKey(conversationID);
  const messageIDs = state.conversationMessages.byConversationID[key];
  if (!messageIDs) return [];
  return buildFromIDArray<DecoratedConversationMessage, Identifier>(
    state,
    'conversationMessages',
    buildConversationMessage,
    messageIDs.map((id) => parseInt(id))
  );
}

export function buildConversationMessage(
  state: RootState,
  messageID: Identifier
) {
  const key = buildKey(messageID);
  return state.conversationMessages.byID[key] || null;
}

export function buildConversationUsers(
  state: RootState,
  conversationID: Identifier
) {
  const key = buildKey(conversationID);
  const idArray = state.conversationUsers.byConversationID[key];
  if (!idArray) return null;
  return buildFromIDArray<DecoratedConversationUser, Identifier>(
    state,
    'conversationUsers',
    buildConversationUser,
    idArray
  );
}

export function buildConversationUser(
  state: RootState,
  conversationUserID: Identifier
) {
  const key = buildKey(conversationUserID);
  return state.conversationUsers.byID[key] || null;
}

export function buildConversationMessageFiles(
  state: RootState,
  messageID: Identifier
) {
  const key = buildKey(messageID);
  const idArray = state.conversationMessageFiles.byMessageID[key];
  if (!idArray) return [];
  return buildFromIDArray<DecoratedConversationMessageFile, Identifier>(
    state,
    'conversationMessageFiles',
    buildConversationMessageFile,
    idArray
  );
}

export function buildConversationMessageFile(
  state: RootState,
  key: Identifier
) {
  if (!state.conversationMessageFiles.byID[key]) return null;
  return {
    ...state.conversationMessageFiles.byID[key],
    file: buildFile(state, state.conversationMessageFiles.byID[key].fileID),
  };
}

export function buildConversationMessageFileCollections(
  state: RootState,
  messageID: Identifier
) {
  const key = buildKey(messageID);
  const idArray = state.conversationMessageFileCollections.byMessageID[key];
  if (!idArray) return [];
  return buildFromIDArray<
    DecoratedConversationMessageFileCollection,
    Identifier
  >(
    state,
    'conversationMessageFileCollections',
    buildConversationMessageFileCollection,
    idArray
  );
}

export function buildConversationMessageFileCollection(
  state: RootState,
  key: Identifier
) {
  if (!state.conversationMessageFileCollections.byID[key]) return null;
  return {
    ...state.conversationMessageFileCollections.byID[key],
    fileCollection: buildFileCollection(
      state,
      state.conversationMessageFileCollections.byID[key].fileCollectionID
    ),
  };
}

export function buildConversationMessageAttachments(
  state: RootState,
  messageID: Identifier
) {
  const key = buildKey(messageID);
  const idArray = state.conversationMessageFileCollections.byMessageID[key];
  if (!idArray || idArray.length === 0)
    return { files: [], fileCollections: [] };
  const { fileCollectionID } =
    state.conversationMessageFileCollections.byID[idArray[0]];
  const files = buildFilesFromFileCollectionID(state, fileCollectionID);
  const fileCollections = buildFileCollectionsFromParentID(
    state,
    fileCollectionID
  );
  return {
    files: files || [],
    fileCollections: fileCollections || [],
  };
}

export function buildSequence(state: RootState, sequenceID: Identifier) {
  const key = buildKey(sequenceID);
  return state.sequences.byID[key] || null;
}

export function buildSequencesFromFileCollectionID(
  state: RootState,
  fileCollectionID: Identifier | null
) {
  if (!fileCollectionID) return [];
  const key = buildKey(fileCollectionID);
  const fileCollectionSequenceIDs =
    state.fileCollectionSequences.byFileCollectionID[key];
  if (!fileCollectionSequenceIDs) return null;
  const sequenceIDs = fileCollectionSequenceIDs.map(parseCompositeID);
  return buildFromIDArray<UndecoratedSequence, Identifier>(
    state,
    'sequences',
    buildSequence,
    sequenceIDs
  );
}

export function buildStepsFromSequenceID(
  state: RootState,
  sequenceID: Identifier | null
) {
  if (!sequenceID) return [];
  const key = buildKey(sequenceID);
  const stepIDs = state.sequenceSteps.bySequenceID[key] || [];
  return buildFromIDArray<UndecoratedSequenceStep, Identifier>(
    state,
    'sequenceSteps',
    buildStep,
    stepIDs
  );
}

export function buildStep(state: RootState, stepID: Identifier | null) {
  if (!stepID) return null;
  const key = buildKey(stepID);
  const step = state.sequenceSteps.byID[key];
  if (!step) return null;
  return step;
}

export function buildStepFilesBySequenceID(
  state: RootState,
  sequenceID: Identifier
) {
  const stepIDs = state.sequenceSteps.bySequenceID[buildKey(sequenceID)];
  if (!stepIDs) return [];
  const keys = stepIDs.flatMap(
    (id) => state.sequenceStepFiles.bySequenceStepID[id] || []
  );
  return buildStepFilesFromIDArray(state, keys);
}

export function buildStepFilesFromIDArray(
  state: RootState,
  idArray: Identifier[]
) {
  return buildFromIDArray<DecoratedSequenceStepFile, Identifier>(
    state,
    'sequenceStepFiles',
    buildSequenceStepFile,
    idArray
  );
}

export function buildSequenceStepFile(state: RootState, key: Identifier) {
  const sequenceStepFile = state.sequenceStepFiles.byID[buildKey(key)];
  if (!sequenceStepFile) return null;
  return {
    ...sequenceStepFile,
    file: buildFile(state, sequenceStepFile.fileID),
  };
}

export function buildFilesByStepID(state: RootState, stepID: Identifier) {
  const keys = state.sequenceStepFiles.bySequenceStepID[buildKey(stepID)];
  if (!keys) return [];
  return buildFilesFromIDArray(state, keys.map(parseCompositeID));
}

export function buildFileCollectionsByStepID(
  state: RootState,
  stepID: Identifier
) {
  const keys =
    state.sequenceStepFileCollections.bySequenceStepID[buildKey(stepID)];
  if (!keys) return [];
  return buildFileCollectionsFromIDArray(state, keys.map(parseCompositeID));
}

export function buildTreeByStepID(state: RootState, stepID: Identifier) {
  const result: TreeItem[] = [];
  const stepFiles = buildFilesByStepID(state, stepID);
  const stepFileCollections = buildFileCollectionsByStepID(state, stepID);
  for (const file of stepFiles) {
    // NOTE: Top-level files won't ever be in a file collection
    result.push(buildTreeFile(file));
  }
  for (const fileCollection of stepFileCollections) {
    // NOTE: Recursively traversing the file collection
    result.push(...traverseFileCollection(state, fileCollection.id));
  }
  return result;
}

function traverseFileCollection(state: RootState, fileCollectionID: number) {
  let queue: number[] = [fileCollectionID];
  let result: TreeItem[] = [];
  while (queue.length > 0) {
    const currentID = queue.shift();
    if (!currentID) continue;
    const files = buildFilesFromFileCollectionID(state, currentID);
    const fileCollections = buildFileCollectionsFromParentID(state, currentID);
    if (files) {
      result.push(...files.map((f) => buildTreeFile(f, currentID)));
    }
    if (fileCollections) {
      result.push(...fileCollections.map(buildTreeFileCollection));
      queue.push(...fileCollections.map((fc) => fc.id));
    }
  }
  return result;
}

function buildTreeFile(file: UndecoratedFile, parentID?: number): TreeItem {
  return {
    id: formatTreeID(file.id, FILE),
    parentID: parentID ? formatTreeID(parentID, FILE_COLLECTION) : null,
    name: file.name,
    type: TREE_ITEM_TYPES.FILE,
  };
}

function buildTreeFileCollection({
  id,
  name,
  parentFileCollectionID,
}: UndecoratedFileCollection): TreeItem {
  return {
    id: formatTreeID(id, FILE_COLLECTION),
    parentID: parentFileCollectionID
      ? formatTreeID(parentFileCollectionID, FILE_COLLECTION)
      : null,
    name,
    type: TREE_ITEM_TYPES.FILE_COLLECTION,
  };
}

export function buildNotification(
  state: RootState,
  notificationID: Identifier
) {
  const key = buildKey(notificationID);
  return state.notifications.byID[key] || null;
}

export function buildAllNotifications(state: RootState) {
  const idArray = Object.keys(state.notifications.byID);
  return buildFromIDArray<Notification, Identifier>(
    state,
    'notifications',
    buildNotification,
    idArray
  );
}

export function buildNotificationsFromIDArray(
  state: RootState,
  idArray: Identifier[]
) {
  return buildFromIDArray<Notification, Identifier>(
    state,
    'notifications',
    buildNotification,
    idArray
  );
}

export function buildUnreadNotificationsCount(state: RootState) {
  return state.unreadNotificationsCount;
}
