import type { AppDispatch } from '@/store/store';
import { SOCKET_EVENTS } from '@/constants/socketEvents';
import type { DecoratedFileCollection } from '@witmetrics/api-client';
import type { Subscribe } from '@/types/sockets';
import {
  addFileCollection,
  deleteFileCollection,
  updateFileCollection,
} from '@/store/slices/fileCollectionsSlice';

const {
  CREATE_FILE_COLLECTION,
  UPDATE_FILE_COLLECTION,
  DELETE_FILE_COLLECTION,
} = SOCKET_EVENTS;

export function subscribeFileCollections(
  dispatch: AppDispatch,
  subscribe: Subscribe
) {
  subscribe({
    endpoint: CREATE_FILE_COLLECTION,
    callback: ({
      fileCollection,
    }: {
      fileCollection: DecoratedFileCollection;
    }) => dispatch(addFileCollection(fileCollection)),
  });
  subscribe({
    endpoint: UPDATE_FILE_COLLECTION,
    callback: ({
      fileCollection,
    }: {
      fileCollection: DecoratedFileCollection;
    }) => dispatch(updateFileCollection(fileCollection)),
  });
  subscribe({
    endpoint: DELETE_FILE_COLLECTION,
    callback: ({ fileCollectionID }: { fileCollectionID: number }) => {
      dispatch(deleteFileCollection({ fileCollectionID }));
    },
  });
}
