import type { AppDispatch } from '@/store/store';
import { SOCKET_EVENTS } from '@/constants/socketEvents';
import type { UndecoratedFileVersion } from '@witmetrics/api-client';
import type { Subscribe } from '@/types/sockets';
import {
  addFileVersion,
  deleteFileVersion,
  updateFileVersion,
} from '@/store/slices/fileVersionsSlice';

const { CREATE_FILE_VERSION, UPDATE_FILE_VERSION, DELETE_FILE_VERSION } =
  SOCKET_EVENTS;

export function subscribeFileVersions(
  dispatch: AppDispatch,
  subscribe: Subscribe
) {
  subscribe({
    endpoint: CREATE_FILE_VERSION,
    callback: ({ fileVersion }: { fileVersion: UndecoratedFileVersion }) =>
      dispatch(addFileVersion(fileVersion)),
  });
  subscribe({
    endpoint: UPDATE_FILE_VERSION,
    callback: ({ fileVersion }: { fileVersion: UndecoratedFileVersion }) =>
      dispatch(updateFileVersion(fileVersion)),
  });
  subscribe({
    endpoint: DELETE_FILE_VERSION,
    callback: ({ fileVersionID }: { fileVersionID: number }) => {
      dispatch(deleteFileVersion({ fileVersionID }));
    },
  });
}
