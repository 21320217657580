import type { AppDispatch } from '@/store/store';
import { SOCKET_EVENTS } from '@/constants/socketEvents';
import type { DecoratedUnisonProjectChecklist } from '@witmetrics/api-client';
import type { Subscribe } from '@/types/sockets';
import { addUnisonProjectChecklist } from '@/store/slices/unisonProjectChecklistsSlice';

const { CREATE_UNISON_PROJECT_CHECKLIST } = SOCKET_EVENTS;

export function subscribeUnisonProjectChecklists(
  dispatch: AppDispatch,
  subscribe: Subscribe
) {
  subscribe({
    endpoint: CREATE_UNISON_PROJECT_CHECKLIST,
    callback: ({
      unisonProjectChecklist,
    }: {
      unisonProjectChecklist: DecoratedUnisonProjectChecklist;
    }) => dispatch(addUnisonProjectChecklist(unisonProjectChecklist)),
  });
}
