export function toTitleCase(str = '') {
  return str
    .split(' ')
    .map(
      (word) =>
        word.slice(0, 1).toUpperCase() +
        word.slice(1, word.length).toLowerCase()
    )
    .join(' ');
}

export function toLowerCase(str = '') {
  return str.toLowerCase();
}

export function renderPrice(
  price = 0,
  currency = 'EUR',
  language = navigator.language
) {
  if (!currency || currency === '') {
    return formatNumber(price);
  }
  return new Intl.NumberFormat(language, {
    style: 'currency',
    currencyDisplay: 'narrowSymbol',
    currency,
  }).format(price);
}

export function isString(val: any): val is string {
  return typeof val === 'string';
}

export function isJSON(str: string) {
  try {
    JSON.parse(str);
    return true;
  } catch (err) {
    return false;
  }
}

export function formatNumber(val: number, fractionDigits = 2) {
  return new Intl.NumberFormat('default', {
    maximumFractionDigits: fractionDigits,
  }).format(val);
}

export function formatPercentage(val: number, fractionDigits = 2) {
  return new Intl.NumberFormat('default', {
    style: 'percent',
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  }).format(val);
}

export function formatFileSize(bytes: number) {
  let amt = bytes;
  const sizeString = (size: number) => formatNumber(size, 1);
  if (amt < 1000) return `${sizeString(amt)} bytes`;
  amt /= 1000;
  if (amt < 1000) return `${sizeString(amt)} kB`;
  amt /= 1000;
  if (amt < 1000) return `${sizeString(amt)} MB`;
  amt /= 1000;
  return `${sizeString(amt)} GB`;
}

export function formatArrayString(str: string) {
  try {
    const arr = JSON.parse(str);
    return arr.join(', ');
  } catch (err) {
    return str;
  }
}

export function renderUserName(
  user?: { firstname: string; lastname?: string },
  includeLastName = true,
  useLastInitial = false
) {
  if (!user) {
    return '';
  } else if (!user.lastname || !includeLastName) {
    return `${user.firstname}`;
  } else if (useLastInitial) {
    return `${user.firstname} ${user.lastname.charAt(0)}.`;
  } else {
    return `${user.firstname} ${user.lastname}`;
  }
}

export function truncateInMiddle(str: string, length: number) {
  if (str.length < length) return str;
  return `${str.slice(0, length / 2)} ... ${str.slice(-length / 2)}`;
}

export function truncateString(str: string, length: number) {
  if (str.length < length) return str;
  return `${str.slice(0, length)}...`;
}

export function isBlank(
  str: string | null | undefined
): str is null | undefined {
  return !str || str.trim().length === 0;
}

export function padNumber(num: number) {
  return `${num}`.length === 1 ? `0${num}` : `${num}`;
}
