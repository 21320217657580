export const FILE_TYPES = {
  // PROFILE_PICTURE: {
  //   id: 1,
  //   name: 'PROFILE_PICTURE',
  //   label: 'Profile Picture',
  // },
  UPPER_ARCH: {
    id: 2,
    name: 'UPPER_ARCH',
    label: 'Upper Arch',
  },
  LOWER_ARCH: {
    id: 3,
    name: 'LOWER_ARCH',
    label: 'Lower Arch',
  },
  OCCLUSION: {
    id: 4,
    name: 'OCCLUSION',
    label: 'Occlusion',
  },
  PANO: {
    id: 5,
    name: 'PANO',
    label: 'Pano',
  },
  FACE: {
    id: 6,
    name: 'FACE',
    label: 'Face',
  },
  EXTRA: {
    id: 7,
    name: 'EXTRA',
    label: 'Extra',
  },
} as const;

export const FILE_TYPE_IDS = {
  // 1: FILE_TYPES.PROFILE_PICTURE.name,
  2: FILE_TYPES.UPPER_ARCH.name,
  3: FILE_TYPES.LOWER_ARCH.name,
  4: FILE_TYPES.OCCLUSION.name,
  5: FILE_TYPES.PANO.name,
  6: FILE_TYPES.FACE.name,
  7: FILE_TYPES.EXTRA.name,
} as const;

export type FileType = keyof typeof FILE_TYPES;

export type FileTypeID = keyof typeof FILE_TYPE_IDS;
