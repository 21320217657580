import type { AppDispatch } from '@/store/store';
import { SOCKET_EVENTS } from '@/constants/socketEvents';
import type { DecoratedConversation } from '@witmetrics/api-client';
import type { Subscribe } from '@/types/sockets';
import {
  addConversation,
  deleteConversation,
  updateConversation,
} from '@/store/slices/conversationsSlice';

const { CREATE_CONVERSATION, UPDATE_CONVERSATION, DELETE_CONVERSATION } =
  SOCKET_EVENTS;

export function subscribeConversations(
  dispatch: AppDispatch,
  subscribe: Subscribe
) {
  subscribe({
    endpoint: CREATE_CONVERSATION,
    callback: ({ conversation }: { conversation: DecoratedConversation }) =>
      dispatch(addConversation(conversation)),
  });
  subscribe({
    endpoint: UPDATE_CONVERSATION,
    callback: ({ conversation }: { conversation: DecoratedConversation }) =>
      dispatch(updateConversation(conversation)),
  });
  subscribe({
    endpoint: DELETE_CONVERSATION,
    callback: ({ conversationID }: { conversationID: number }) => {
      dispatch(deleteConversation({ conversationID }));
    },
  });
}
